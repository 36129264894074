<template>
  <div class="container-fluid products">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <div class="col-auto my-auto d-flex align-items-center">
              <h5>Products</h5>

              <div class="col text-end">
                <button
                  @click="manageCategories()"
                  class="btn btn-outline-secondary btn-sm me-2"
                >
                  <i class="far fa-edit me-1"></i>
                  Categories
                </button>
                <button
                  @click="addProduct()"
                  class="btn btn-outline-primary btn-sm"
                >
                  <i class="far fa-plus me-1"></i>
                  Add product
                </button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-auto my-auto d-flex align-items-center form-inline">
                <select
                  class="form-control form-control-sm me-2"
                  v-model="searchField"
                >
                  <option value="title">Title</option>
                  <option value="sku">SKU</option>
                  <option value="category">Category</option>
                </select>
                <input
                  type="text"
                  v-model="searchTerm"
                  @input="search"
                  class="form-control form-control-sm"
                  autocomplete="on"
                  placeholder="Enter search query"
                />
              </div>
              <div class="col-auto ms-auto d-flex align-items-center form-inline">
                <div class="my-4">
                  <button
                    class="btn btn-sm btn-outline-dark me-1 cursor-pointer"
                    :class="sortDirection != 'desc' ? 'active' : ''"
                    @click="sortDir('asc')"
                  >
                    <i class="far fa-sort-size-up"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-outline-dark me-2 cursor-pointer"
                    :class="sortDirection == 'desc' ? 'active' : ''"
                    @click="sortDir('desc')"
                  >
                    <i class="far fa-sort-size-down"></i>
                  </button>
                </div>
                <div class="dropdown">
                  <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    id="sortingDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="far fa-sort me-2"></i>
                    <span class="text-capitalize">{{ sortField | formatLabel }}</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sortingDropdown">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="sortBy('title')"
                    >
                      Title
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="sortBy('sku')"
                    >
                      SKU
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="sortBy('category')"
                    >
                      Category
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="sortBy('price')"
                    >
                      Price
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="sortBy('created_at')"
                    >
                      Created At
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-5" v-if="!loading">
              <div class="col">
                <table v-if="products.length" class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th class="desktop-col">SKU</th>
                      <th class="desktop-col">Category</th>
                      <th>Price</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(p) in products" :key="p.id" @click="viewProduct(p)">
                      <td>{{ p.title }}</td>
                      <td class="desktop-col">{{ p.sku }}</td>
                      <td class="desktop-col">{{ p.category_title ? p.category_title : '-' }}</td>
                      <td>{{ getCurrencySymbol(p) }}{{ p.price.toFixed(2) }}</td>
                      <td>{{ p.created_at | formatDateTime }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="!products.length" class="my-2">
                  No products found
                </div>
              </div>
            </div>
            <loading class="mb-5" v-else></loading>
          </div>

          <div class="card-footer">
            <div class="row pagination">
              <div class="col">
                <button
                  @click="fetchProducts(pagination.first_page_url)"
                  :disabled="!pagination || !pagination.first_page_url
                    || pagination.current_page == 1"
                  class="btn btn-light btn-sm me-3"
                >
                  First Page
                </button>
                <button
                  @click="fetchProducts(pagination.prev_page_url)"
                  :disabled="!pagination || !pagination.prev_page_url"
                  class="btn btn-light btn-sm"
                >
                  Previous Page
                </button>
              </div>
              <div class="col text-center my-auto">
                <i class="small">
                  Page {{ pagination ? pagination.current_page : 1 }}
                  of
                  {{ pagination ? pagination.last_page: 1 }}
                </i>
              </div>
              <div class="col text-end">
                <button
                  @click="fetchProducts(pagination.next_page_url)"
                  :disabled="!pagination || !pagination.next_page_url"
                  class="btn btn-light btn-sm me-3"
                >
                  Next Page
                </button>
                <button
                  @click="fetchProducts(pagination.last_page_url)"
                  :disabled="!pagination || !pagination.last_page_url
                    || pagination.current_page == pagination.last_page"
                  class="btn btn-light btn-sm"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import debounce from "debounce";
  import Loading from "@/views/components/loader/Loading";

  export default {
    props: [],
    data() {
      return {
        loading: true,
        pagination: null,
        products: [],
        searchField: "title",
        searchParam: "",
        searchTerm: "",
        selectedProduct: {},
        sortDirection: "asc",
        sortField: null
      };
    },
    methods: {
      addProduct() {
        this.$router.push('/settings/products/create');
      },
      fetchProducts: debounce(function(paginationUrl) {
        var url = paginationUrl
          ? paginationUrl
          : process.env.VUE_APP_API_URL + "/products/custom";

        if (this.searchParam) {
          url = this.updateQueryStringParameter(url, "search_term", this.searchParam);
          url = this.updateQueryStringParameter(url, "search_field", this.searchField);
        }

        if (this.sortField) {
          url = this.updateQueryStringParameter(url, "sort_field", this.sortField);
        }
        if (this.sortDirection) {
          url = this.updateQueryStringParameter(url, "sort_direction", this.sortDirection);
        }

        this.loading = true;

        this.$axios.get(url).then(({ data }) => {
          this.pagination = data;
          this.products = data.data;
          this.loading = false;
        });
      }, 300),
      getCurrencySymbol(p) {
        return p.product && p.product.currency
          ? p.product.currency.symbol : this.$stash.currencies[0].symbol;
      },
      manageCategories() {
        this.$router.push('/settings/products/categories');
      },
      search: debounce(function() {
        if (this.searchTerm.length > 2) {
          this.searchParam = this.searchTerm;
        } else {
          this.searchParam = "";
        }
        this.fetchProducts();
      }, 300),
      sortBy(field) {
        this.sortField = field;
        this.fetchProducts();
      },
      sortDir(direction) {
        this.sortDirection = direction;
        this.fetchProducts();
      },
      updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";

        if (uri.match(re)) {
          return uri.replace(re, "$1" + key + "=" + value + "$2");
        } else {
          return uri + separator + key + "=" + value;
        }
      },
      viewProduct(product) {
        this.$router.push('/settings/products/' + product.id);
      }
    },
    created() {
      this.fetchProducts();
    },
    filters: {
      formatDateTime(date) {
        return moment(date).format("LLL");
      },
      formatLabel(str) {
        if (!str) {
          return "Sorting";
        } else {
          return str.replace(/_/g, " ");
        }
      },
    },
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
      path() {
        return this.$stash.path;
      }
    },
    components: {
      Loading
    }
  };
</script>

<style scoped>
  .table thead > tr > th {
    border-top: 0px;
    border-bottom: 1px;
  }

  @media (max-width: 768px) {
    .spinner-border {
      margin-top: 2rem;
    }

    .card-header > .d-flex {
      flex-direction: column;
    }

    hr {
      display: none;
    }

    .desktop-col {
      display: none;
    }

    .pagination {
      flex-direction: column;
    }

    .btn-outline-primary,
    .btn-outline-secondary,
    .pagination > .col > button {
      width: 100%;
    }

    .pagination > .col > button {
      margin-top: 0.5rem;
    }
  }
</style>